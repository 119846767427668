// Typography
body {
    font-size: 16px;
    color: $text;
    font-display: auto;
    font-weight: 400;
    font-family: $body-font;
    line-height: 1.5;
}

.txtc {
    text-align: center;
}

.txtr {
    text-align: right;
}

.hero {
    font-family: $title-font;
}

.hero-bold {
    text-transform: uppercase;
    font-weight: 800;
    font-family: $body-font;
}

.brand {
    color: $brand;
}

.highlight {
    color: $highlight;
}

.block {
    display: block;
}

.margin-top {
    margin-top: 50px;
}

a {
    color: $highlight;
}

strong {
    font-weight: bold;
}

h1 {
    margin: 0 0 0.25em 0;
    line-height: 1.25;
    color: $black;
    font-weight: 900;
    font-family: $title-font;
}

h2 {
    margin: 0 0 0.25em 0;
    line-height: 1.25;
    font-weight: 900;
    font-family: $title-font;

    .title-sm {
        font-size: 0.6em;
        font-weight: normal;
    }
}

h3 {
    font-size: 1.4em;
    margin: 0 0 0.5em 0;
    line-height: 1.25;
    font-weight: normal;
}

h4 {
    font-size: 1.2em;
    line-height: 1.25;
    margin: 0 0 0.25em 0;
    font-weight: normal;
}

h5 {
    margin: 0;
    font-size: 1em;
    line-height: 1.25;
    font-weight: 900;
    font-family: $title-font;
    color: $highlight;
}

p {
    margin: 0 0 1em 0;

    &:last-child {
        margin: 0;
    }
}

sup {
    font-size: 0.6em;
}

.q {
    font-family: $title-font;
    color: $title;
    margin: 0 0 $gutter 0;
    padding: 0 $gutter;
    border-left: 5px solid $brand;
}

.sep {
    margin: ($gutter / 2) 0;
    display: block;
    padding: 0;
    border: 0;
    clear: both;
    position: relative;
    border-bottom: 1px solid $border;

    [class^="icon-"] {
        position: absolute;
        display: block;
        top: 0;
        left: 50%;
        width: 16px;
        height: 16px;
        line-height: 1;
        text-align: center;
        background-color: transparent;
        color: $border;
        transform: translate(-50%, -50%);

        &::before {
            margin: 0;
        }
    }
}

.text-sm {
    font-size: 0.9em;
}

@media (min-width: $bp-l) {
    body {
        font-size: 17px;
    }

    h1 {
        font-size: 2.569em;
    }

    h2 {
        font-size: 1.8em;
    }

    h3 {
        font-size: 1.333em;
    }

    h4 {
        font-size: 1.2em;
    }

    .lg,
    .hero {
        font-size: 1.2em;
    }

    .sm {
        font-size: 0.75em;
    }
}

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    body {
        font-size: 16px;
    }

    h1 {
        font-size: 2.441em;
    }

    h2 {
        font-size: 1.4em;
    }

    h3 {
        font-size: 1.3em;
    }

    h4 {
        font-size: 1.1em;
    }

    .lg,
    .hero {
        font-size: 1.1em;
    }

    .sm {
        font-size: 0.8em;
    }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    body {
        font-size: 15px;
    }

    h1 {
        font-size: 2.074em;
    }

    h2 {
        font-size: 1.728em;
    }

    h3 {
        font-size: 1.44em;
    }

    h4 {
        font-size: 1.2em;
    }

    .lg,
    .hero {
        font-size: 1.2em;
    }

    .sm {
        font-size: 0.833em;
    }
}
