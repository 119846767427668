@font-face {
  font-family: 'siteicons';
  src: url('../fonts/site.eot?76113967');
  src: url('../fonts/site.eot?76113967#iefix') format('embedded-opentype'),
       url('../fonts/site.woff2?76113967') format('woff2'),
       url('../fonts/site.woff?76113967') format('woff'),
       url('../fonts/site.ttf?76113967') format('truetype'),
       url('../fonts/site.svg?76113967#site') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'site';
    src: url('../font/site.svg?76113967#site') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "siteicons";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

// Arrows
.icon-up-open:before { content: '\e800'; } /* '' */
.icon-right-open:before { content: '\e801'; } /* '' */
.icon-left-open:before { content: '\e802'; } /* '' */
.icon-down-open:before { content: '\e803'; } /* '' */
.icon-down:before { content: '\e830'; } /* '' */
.icon-left:before { content: '\e831'; } /* '' */
.icon-right:before { content: '\e832'; } /* '' */
.icon-up:before { content: '\e833'; } /* '' */

// Symbols
.icon-plus:before { content: '\e804'; } /* '' */
.icon-minus:before { content: '\e805'; } /* '' */
.icon-menu:before { content: '\e806'; } /* '' */
.icon-link:before { content: '\e807'; } /* '' */
.icon-phone:before { content: '\e808'; } /* '' */
.icon-mail:before { content: '\e809'; } /* '' */
.icon-heart:before { content: '\e80a'; } /* '' */
.icon-star:before { content: '\e80b'; } /* '' */
.icon-users:before { content: '\e80c'; } /* '' */
.icon-location:before { content: '\e80d'; } /* '' */
.icon-user:before { content: '\e80e'; } /* '' */
.icon-search:before { content: '\e80f'; } /* '' */
.icon-cancel:before { content: '\e810'; } /* '' */
.icon-check:before { content: '\e811'; } /* '' */
.icon-attach:before { content: '\e812'; } /* '' */
.icon-tag:before { content: '\e813'; } /* '' */
.icon-tags:before { content: '\e814'; } /* '' */
.icon-eye:before { content: '\e815'; } /* '' */
.icon-home:before { content: '\e816'; } /* '' */
.icon-lock:before { content: '\e817'; } /* '' */
.icon-lock-open:before { content: '\e818'; } /* '' */
.icon-info:before { content: '\e819'; } /* '' */
.icon-attention:before { content: '\e81a'; } /* '' */
.icon-bell:before { content: '\e81b'; } /* '' */
.icon-print:before { content: '\e81c'; } /* '' */
.icon-pencil:before { content: '\e81d'; } /* '' */
.icon-quote:before { content: '\e81e'; } /* '' */
.icon-box:before { content: '\e81f'; } /* '' */
.icon-cog:before { content: '\e820'; } /* '' */
.icon-basket:before { content: '\e821'; } /* '' */
.icon-record:before { content: '\e822'; } /* '' */
.icon-briefcase:before { content: '\e823'; } /* '' */
.icon-bookmark:before { content: '\e824'; } /* '' */
.icon-lifebuoy:before { content: '\e825'; } /* '' */
.icon-globe:before { content: '\e826'; } /* '' */
.icon-credit-card:before { content: '\e827'; } /* '' */
.icon-inbox:before { content: '\e828'; } /* '' */
.icon-comment:before { content: '\e829'; } /* '' */
.icon-share:before { content: '\e82a'; } /* '' */
.icon-paper-plane:before { content: '\e82b'; } /* '' */
.icon-download:before { content: '\e82c'; } /* '' */
.icon-play:before { content: '\e82d'; } /* '' */
.icon-asterisk:before { content: '\e82e'; } /* '' */
.icon-mobile:before { content: '\e82f'; } /* '' */
.icon-clock:before { content: '\e834'; } /* '' */
.icon-bookmark-empty:before { content: '\f097'; } /* '' */
.icon-certificate:before { content: '\f0a3'; } /* '' */
.icon-thumbs-up-alt:before { content: '\f164'; } /* '' */
.icon-thumbs-down-alt:before { content: '\f165'; } /* '' */

// Social
.icon-youtube:before { content: '\f16a'; } /* '' */
.icon-google:before { content: '\f1a0'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-twitter:before { content: '\f309'; } /* '' */
.icon-facebook:before { content: '\f30e'; } /* '' */
.icon-linkedin:before { content: '\f318'; } /* '' */
.icon-instagram:before { content: '\f32d'; } /* '' */