// * Grid system
// * Define the properties of the flexible container
// *

// Output the grid only if variable set to true
@if ($include-grid) {
    .wrap {
        overflow: hidden;
        width: 100%;
    }

    .container {
        width: 96%;
        padding: 0 ($gutter / 4);
        box-sizing: border-box;
        margin: 0 auto;
        max-width: $m-max-width;

        &::after {
            clear: both;
            content: '';
            display: block;
        }

        .container {
            width: 102%;
            width: calc(100% + #{$gutter});
            margin-left: -($gutter / 2);
        }
    }

    .col12,
    .col11,
    .col10,
    .col9,
    .col8,
    .col7 {
        margin: 0;
        padding: 0 ($gutter / 2);
        width: 100%;
        box-sizing: border-box;
    }

    .col6,
    .col5,
    .col4,
    .col3 {
        float: left;
        margin: 0;
        padding: 0 ($gutter / 2);
        width: 50%;
        box-sizing: border-box;
    }

    .col2 {
        float: left;
        margin: 0;
        padding: 0 ($gutter / 2);
        width: 33.33%;
        box-sizing: border-box;
    }

    .col1 {
        float: left;
        margin: 0;
        padding: 0 ($gutter / 2);
        width: 25%;
        box-sizing: border-box;
    }

    .m-full {
        width: 100%;

        &.colr {
            float: none;
        }
    }

    .m-half,
    .m-2_4 {
        float: left;
        width: 50%;
    }

    .m-3_4 {
        float: left;
        width: 75%;
    }

    .m-2_3 {
        float: left;
        width: 66.66%;
    }

    .m-1_3 {
        float: left;
        width: 33.33%;
    }

    .m-1_4 {
        float: left;
        width: 25%;
    }

    // * Additional grid utilities
    .colr {
        float: right;
    }

    .alpha {
        margin-left: 0;
    }

    .omega {
        margin-right: 0;
    }

    .clear {
        clear: both;
    }

    .center {
        float: none;
        margin: 0 auto;
    }
}

// * Show or hide elements by breakpoint
.m-hide {
    display: none;
}

.m-show-block,
.m-show-inline {
    display: inline;
}

.s-show-block,
.s-show-block,
.l-show-inline,
.l-show-inline {
    display: none;
}
