// Logos
.logos-carousel {
    margin: 0 0 $gutter 0;
    padding: 0;
}

.logos-item {
    margin: ($gutter / 2);
    text-align: center;
    
}

.logos-img {
    width: 150px !important;
    display: block;
    margin: 0 auto;
}

// @media (min-width: $bp-l) {

// }

// @media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {

// }

// @media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {

// }
