// Gallery
.gallery {
    display: flex;
    flex-flow: row wrap;
}

.gallery-lnk {
    display: block;
    box-sizing: border-box;
    flex: 1 1 auto;
}

.gallery-thumb {
    display: block;
}

@media (min-width: $bp-l) {
    .gallery-lnk {
        flex-basis: 16%;
        padding: ($gutter / 4);
    }
}

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    .gallery-lnk {
        flex-basis: 25%;
        padding: ($gutter / 4);
    }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .gallery-lnk {
        flex-basis: 50%;
        padding: ($gutter / 4);
    }
}
