// Custom styles
.pro-imgs {
    img {
        border: 1px solid $black;
        display: block;
        box-sizing: border-box;
    }
}

.box-precios {
    position: relative;
    padding-right: 140px;

    img {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -60px;
    }
}

.table-horarios {
    width: 100%;
    margin: 0 0 $gutter 0;
    border-collapse: collapse;
    text-align: left;

    th,
    td {
        border-top: 1px solid $bg-lt;
        border-bottom: 1px solid $bg-lt;
        text-align: left;
        padding: 5px 10px;
    }

    ul {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0 0 0 24px;
    }
}

.logos-row {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        margin: 0 ($gutter / 2);
    }
}

// Reviews
.review {
    position: relative;
    padding-left: 46px;

    .rv-avatar {
        width: 36px;
        height: 36px;
        position: absolute;
        left: 0;
        top: 0;
    }

    .rv-author {
        font-size: 1.1em;
        font-weight: bold;
    }

    .rv-visit {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-indent: -100px;
    }

    .rv-cont {
        .stars {
            img {
                display: inline-block;
                width: 14px !important;
                height: 14px !important;
            }
        }
    }
}


.accordion {
    display: none;
}

.accordion-toggle {
    margin: 0;
    padding: 6px 10px;
    border-radius: 3px;
    border: 0;
    background: none;
    appearance: none;
    background-color: $bg-lt;
}

@media (min-width: $bp-l) {
    // #contacto {
        // background-image: url('/assets/img/bg-foot-lg.jpg');
    // }
}

// @media (min-width: $bp-xl) {


// }

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    // #contacto {
        // background-image: url('/assets/img/bg-foot-md.jpg');
    // }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    // #contacto {
        // background-image: url('/assets/img/bg-foot-sm.jpg');
    // }
}