// Main header
.main-head {
    background-color: $white;
    padding: ($gutter / 2) 0;
    box-shadow: 0 2px 2px rgba($bg-dk, 0.2);
    transition: all 0.15s ease;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
}

.main-brand {
    float: left;
    transition: all 0.15s ease;

    .brand-lnk {
        display: block;
        overflow: hidden;
        height: 100%;
        text-decoration: none;
        color: $text;
        font-weight: bold;
        line-height: 300px;
        transition: all 0.15s ease;
    }

    .brand-logo {
        display: block;
        height: 100%;
        width: auto;
        text-decoration: none;
        color: $text;
        transition: all 0.15s ease;
    }
}

.brand-lnk {
    display: block;
}

@media (min-width: $bp-l) {
    .main-head {
        padding: $gutter 0;
        height: 40px;
    }

    .main-brand {
        height: 60px;
        margin-top: -10px;
    }

    .brand-lnk {
        font-size: 1.6em;
        line-height: (40px + $gutter);
    }

    .small-head {
        @if($dark-head) {
            .main-head {
                padding: ($gutter / 4) 0;
                box-shadow: none;
                background-color: rgba($black, 0.9);
            }
        } @else {
            .main-head {
                padding: ($gutter / 4) 0;
            }
        }

        .main-brand {
            margin-top: 0;
            height: 40px;
        }

        .brand-lnk {
            line-height: (40px + ($gutter / 2));
        }
    }
}

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    .main-head {
        height: 40px;
    }

    .main-brand {
        height: (40px + ($gutter / 2));
        margin-top: (-($gutter / 4));
    }

    .brand-lnk {
        font-size: 1.4em;
        line-height: (40px + ($gutter / 2));
    }

    .small-head {
        @if $dark-head == true {
            .main-head {
                padding: ($gutter / 4) 0;
                box-shadow: none;
                background-color: rgba($black, 0.8);
            }
        } @else {
            .main-head {
                padding: 3px 0;
            }
        }

        .main-brand {
            height: 36px;
            margin-top: 2px;
        }

        .brand-lnk {
            line-height: 40px;
        }
    }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .main-head {
        height: 32px;
        padding: 6px 0;

        .col12 {
            padding: 0;
        }
    }

    .main-brand {
        height: 32px;
    }

    .brand-lnk {
        font-size: 1.2em;
        line-height: 30px;
    }
}
