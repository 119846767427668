// Content media
.media {
    img {
        display: block;
    }
}

@media (min-width: $bp-l) {
    .content-media {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.cm-top {
            align-items: flex-start;
        }

        .media-content {
            flex: 0 0 46%;
            padding: 0 0 ($gutter / 2) 4%;
        }

        .media-media {
            flex: 0 0 50%;
        }

        &.reverse {
            flex-flow: row-reverse nowrap;

            .media-content {
                padding: 0 4% ($gutter / 2) 0;
            }
        }
    }
}

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    .content-media {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.cm-top {
            align-items: flex-start;
        }

        .media-content {
            flex: 0 0 46%;
            padding: 0 0 ($gutter / 2) 4%;
        }

        .media-media {
            flex: 0 0 50%;
        }

        &.reverse {
            flex-flow: row-reverse nowrap;

            .media-content {
                padding: 0 4% ($gutter / 2) 0;
            }
        }
    }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .media-content {
        padding: ($gutter / 2);
    }
}
