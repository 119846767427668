// Navigation
.menu {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-item {
    margin: 0;
    padding: 0;
}

.nav-lnk {
    display: block;
    padding: 10px 5px;
    text-decoration: none;
    color: $text;
    font-size: 0.62em;
    text-transform: uppercase;
    transition: all 0.15s linear;

    &:hover {
        color: $brand;
    }
    
    &.nav-lnk-en,
    &.nav-lnk-es {
        font-weight: bold;
        padding-left: 50px;
        background: {
            size: 24px 24px;
            position:45% 45%;
            repeat: no-repeat;
        }
    }
    
    &.nav-lnk-en {
        background-image: url('#{$img}flag-us.png');
    }

    &.nav-lnk-es {
        background-image: url('#{$img}flag-mx.png');  
    }
}

.nav-lnk-facebook {
    color: $facebook;
}

.nav-lnk-instagram {
    color: $instagram;
}

.nav-lnk-whatsapp {
    color: $whatsapp;
}

@media (min-width: $bp-l) {
    .btn-menu {
        display: none;
    }

    .main-nav {
        float: right;
    }

    .nav-item {
        float: left;
    }

    .nav-lnk {
        height: 20px;
        padding: 10px;
        line-height: 20px;
    
        &.nav-lnk-en,
        &.nav-lnk-es {
            margin-left: 24px;
        }
    }

    @if $dark-head == true {
        .small-head {
            .nav-lnk {
                color: $white;

                &:hover {
                    color: $brand;
                }
            }
        }
    }
}

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    .btn-menu {
        display: none;
    }

    .main-nav {
        float: right;
    }

    .nav-item {
        float: left;
    }

    .nav-lnk {
        height: 20px;
        line-height: 20px;
    }

    @if $dark-head == true {
        .small-head {
            .nav-lnk {
                color: $white;
            }
        }
    }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .btn-menu {
        display: block;
        position: absolute;
        top: 2px;
        right: 0;
        color: $text;
        padding: 9px;
        background: none;
        border: 0;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1em;

        .icon-cancel {
            display: none;
        }
    }

    .main-nav {
        position: absolute;
        z-index: 97;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $white;
    }

    .nav-item {
        height: 0;
        overflow: hidden;
        transition: height 0.15s ease;
    }

    .nav-lnk {
        border-top: 1px solid $bg-lt;
        text-align: center;

        &:hover {
            background-color: $bg-lt;
        }
    }

    .open-menu {
        .btn-menu {
            .icon-cancel {
                display: inline;
            }

            .icon-menu {
                display: none;
            }
        }

        .main-nav {
            box-shadow: 0 6px 6px rgba($bg-dk, 0.1);
        }

        .nav-item {
            height: 39px;
        }
    }
}
