// Tabs
.tabs-list {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
}

.tab-item {
    display: block;
    margin: 0;
    padding: 0;
    flex-grow: 1;

    &:first-child {
        margin: 0;
    }
}

.tab-lnk {
    position: relative;
    z-index: 0;
    text-decoration: none;
    display: block;
    padding: 12px 0;
    font-size: 0.8em;
    line-height: 1.2;
    background-color: $brand;
    color: $white;
    text-align: center;
    overflow: hidden;
    // border-radius: $g-radius $g-radius 1px 1px;
    border: 1px solid $brand;
    border-bottom-color: $white;

    &.tab-current {
        color: $brand;
        z-index: 9;
        border-color: $border;
        border-bottom-color: $white;
        background-color: $white;
    }
}

.tab-panel {
    display: none;
    position: relative;
    z-index: 1;
    margin-top: -1px;
    border: 1px solid $border;
    background-color: $white;

    &.tab-open {
        display: block;
    }
}

.tab-cont {
    padding: $gutter;
}

@media (min-width: $bp-l) {
    .tab-lnk-mob {
        display: none;
    }

    .tab-item {
        margin: 0 0 0 ($gutter / 4);
        flex: 1 1 0;
    }
}

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    .tab-lnk-mob {
        display: none;
    }

    .tab-item {
        margin: 0 0 0 2px;
        flex: 1 1 0;
    }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .tabs-list {
        display: none;
    }

    .tabs {
        border-top: 1px solid $brand;
    }

    .tab-lnk-mob {
        display: block;
        color: $brand;
        background-color: $white;
        text-decoration: none;
        border: 1px solid $brand;
        border-top: 0;
        padding: ($gutter / 2) 40px ($gutter / 2) ($gutter / 2);
        position: relative;

        .icon-down-open {
            position: absolute;
            top: 11px;
            right: 6px;
        }

        &.tab-current {
            background-color: $brand;
            color: $white;

            .icon-down-open {
                transform: rotate(180deg);
            }
        }
    }

    .tab-panel {
        border-color: $brand;
    }

    .tab-cont {
        padding: ($gutter / 2);
    }
}
