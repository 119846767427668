// Owl Carousel
.slide {
	padding: 60px;
	color: $black;
	z-index: 10;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	background: {
		color: $bg-dk;
		position: 50%;
		size: cover;
		repeat: no-repeat;
	}

	p {
		font-family: $title-font;
		font-size: 2.2em;
		font-weight: bold;
		transition: all 0.25s ease;
		transition-delay: 0.45s;
		background-color: rgba($white, 0.75);
	}

	.container {
		position: relative;
		z-index: 10;
	}
}

.owl-item {
	h1,
	p {
		opacity: 0;
		transform: translateY(12px);
	}

	&.active {
		h1,
		p {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

// .ovly {
// 	position: absolute;
// 	z-index: 0;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	background-color: rgba($black, 0.6);
// }

.banner-carousel {
	background-color: $bg-dk;
	color: $white;
}
	
.owl-carousel {
	position: relative;

	.owl-nav {
		text-align: center;
		color: $white;
		font-size: 28px;
		margin: 0;
		-webkit-tap-highlight-color: transparent;
	}

	.owl-prev,
	.owl-next {
		position: absolute;
		top: 50%;
		z-index: 99;
		color: $white;
		display: block;
		cursor: pointer;
		width: 30px;
		height: 48px;
		line-height: 48px;
		font-family: 'siteicons';
		margin: -24px 0 0 0;
		transition: all 0.15s ease;
		opacity: 0.75;

		&:hover {
			text-decoration: none;
			opacity: 1;
		}

		.icon-left-open,
		.icon-right-open {
			display: block;
			font-size: 30px !important;
			height: 48px;
			line-height: 48px;
			margin: 0;
			width: 30px !important;

			&:before {
				margin: 0;
			}
		}
	}

	.owl-prev {
		left: ($gutter / 2);

		&:hover {
			transform: translateX(-6px);
		}
	}

	.owl-next {
		right: ($gutter / 2);

		&:hover {
			transform: translateX(6px);
		}
	}

	.disabled {
		opacity: 0.4;
		cursor: default;
	}

	// Styling dots
	.owl-nav.disabled + .owl-dots {
		margin-top: 10px;
	}

	.owl-dots {
		display: none;
		position: absolute;
		bottom: 20px;
		width: 100%;
		padding: ($gutter / 4) 0;
		text-align: center;
		-webkit-tap-highlight-color: transparent;
	}

	.owl-dot {
		display: inline-block;
		zoom: 1;

		span {
			width: 10px;
			height: 10px;
			margin: 0 4px;
			border: 1px solid $black;
			background-color: rgba($white, 0.2);
			display: block;
			backface-visibility: visible;
			transition: opacity 200ms ease;
			border-radius: 6px;
		}

		&.active,
		&:hover {
			span {
				background-color: $brand;
			}
		}
	}
}

@media (min-width: $bp-l) {
	.banner-carousel {
		height: 520px;
	}

	.banner-video {
		height: 520px;
		margin: 0;
	}

	.slide {
		height: 400px;
	}

	.slide-1 {
		background-image: url('#{$img}banners/invisalign-lg.jpg');
	}

	.slide-2 {
		background-image: url('#{$img}banners/banner-2-lg.jpg');
	}

	.slide-3 {
		background-image: url('#{$img}banners/banner-lg.jpg');
	}
}


@media (min-width: $bp-xl) {
	.banner-carousel {
		height: 620px;
	}

	.banner-video {
		height: 620px;
	}

	.slide {
		height: 500px;
	}
}

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
	.banner-carousel {
		height: 420px;
	}

	.banner-video {
		height: 420px;
	}

	.slide {
		height: 300px;
	}

	.slide-1 {
		background-image: url('#{$img}banners/invisalign-md.jpg');
	}

	.slide-2 {
		background-image: url('#{$img}banners/banner-2-md.jpg');
	}

	.slide-3 {
		background-image: url('#{$img}banners/banner-md.jpg');
	}
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
	.banner-carousel {
		height: 270px;
	}

	.banner-video {
		height: 268px;
		margin: 0;
	}

	.slide {
		height: 220px;
		padding: $gutter;
	}

	.slide-1 {
		background-image: url('#{$img}banners/invisalign-sm.jpg');
	}

	.slide-2 {
		background-image: url('#{$img}banners/banner-2-sm.jpg');
	}

	.slide-3 {
		background-image: url('#{$img}banners/banner-sm.jpg');
	}
	
	.owl-carousel {
		.owl-prev {
			left: ($gutter / 4);
		}

		.owl-next {
			right: ($gutter / 4);
		}
	}
}
