// Body
html,
body {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
}

a {
    img {
        border: 0;
    }
}

/*
[class^="col"] {
    outline: 1px solid green;
}
*/

@media (min-width: $bp-l) {
    .wrap {
        padding-top: (40px + ($gutter * 2));
    }
}
@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    .wrap {
        padding-top: (40px + $gutter);
    }
}
@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .wrap {
        padding-top: 44px;
    }
}