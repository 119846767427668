// Buttons
.btn {
    display: inline-block;
    border-radius: $g-radius;
    padding: ($gutter / 2) $gutter;
    text-decoration: none;
    transition: all 0.15s linear;
    margin: 0 2px 2px 0;
    text-align: center;
    color: $brand;
    border: 1px solid $brand;
    line-height: 1;
    font-size: 1em;
    font-family: $body-font;
    font-weight: normal;
    cursor: pointer;
    white-space: nowrap;
    appearance: none;
    background: {
        color: transparent;
    }

    &:hover {
        color: mix($black, $brand, 20%);
        background-color: rgba($white, 0.8);
        text-decoration: none;
    }
    
    &:focus,
    &.focus {
        outline: 0;
        text-decoration: none;
        box-shadow: none;
    }

    &.disabled,
    &[disabled] {
        opacity: 0.6;
        pointer-events: none;
    }
}

.btn-cta {
    color: $white;
    border-color: transparent;
    background-color: $highlight;

    &:hover {
        color: $white;
        text-shadow: 1px 1px 4px rgba($black, 0.1);
        box-shadow: 0 0 16px rgba($black, 0.2);
        background: {
            image: none;
            color: mix($white, $highlight, 15%);
        }
    }
}

.btn-w {
    color: $black;
    border-color: transparent;
    background-color: $white;

    &:hover {
        color: $black;
        background-color: mix($white, $highlight, 75%);
    }
}

.btn-full {
    display: block;
    width: 100%;
    margin: ($gutter / 8) 0;
}

.btn-sm {
    font-size: 0.75em;
    padding: ($gutter / 4) ($gutter / 2);
}

.btn-xl {
    border: 0;
    font-size: 1.1em;
    padding: 18px 48px;
}

@media (min-width: $bp-l) {
    .btn {
        padding-left: ($gutter * 2);
        padding-right: ($gutter * 2);
    }

    .btn-sm {
        font-size: 0.75em;
    }
}

// @media (min-width: $bp-xl) {

// }

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    .btn-sm {
        font-size: 0.8em;
    }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .btn-sm {
        font-size: 0.833;
    }
}
