// Social icons
.social-icons {
    margin: 0 auto;
    padding: 0 0 ($gutter / 2) 0;
    text-align: center;

    .social-item {
        display: inline-block;
        margin: 0 ($gutter / 4);
        padding: 0;
    }

    .social-lnk {
        display: inline-block;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 41px;
        color: $white;
        font-size: 14px;
        text-decoration: none;
        overflow: hidden;
        border-radius: 22px;
        background-color: rgba($black, 0.2);
        transition: all 0.15s linear;

        &:hover {
            color: $white;
            background-color: $black;
        }
    }
}

.social-title {
    font-family: $title-font;
    color: $brand;
    margin: 0 ($gutter / 2) ($gutter / 2) ($gutter / 2);
}

.social-txt {
    display: none;
}

// @media (min-width: $bp-l) {

// }

// @media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {

// }

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .social-icons {
        max-width: 140px;

        .social-item {
            margin: 0 2px;
        }
    }
}
