// Quote
.quote {
    padding: 0 $gutter $gutter $gutter;
    margin: 0;
    text-align: center;

    .icon-quote {
        font-size: 3em;
        color: $brand;
    }
}

.quote-copy {
    font-family: $title-font;
}

.quote-brand {
    background-color: $brand;
    color: $white;

    .icon-quote {
        color: $title;
    }

    .quote-author {
        color: $title;
    }
}

@media (min-width: $bp-l) {
    .quote {
        .icon-quote {
            font-size: 4em;
        }
    }

    .quote-copy {
        font-size: 2.369em;
    }
}

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    .quote-copy {
        font-size: 1.563em;
    }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .quote-copy {
        font-size: 1.44em;
    }
}
