// Animation
.section {
    &.in {
        .slin {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .a1 {
        transition-delay: 0.1s;
    }

    .a2 {
        transition-delay: 0.25s;
    }

    .a3 {
        transition-delay: 0.4s;
    }

    .a4 {
        transition-delay: 0.55s;
    }

    .a5 {
        transition-delay: 0.7s;
    }

    .a6 {
        transition-delay: 0.85s;
    }
}

.setanim {
    .slin {
        transition: {
            property: transform, opacity;
            duration: 0.5s;
            timing-function: ease-out;
        }
        transform: translateY($gutter);
        opacity: 0;
    }
}


// @media (min-width: $bp-l) {

// }

// @media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {

// }

// @media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {

// }
    