// Top banner
.top-banner {
    background-color: $bg-lt;
    padding: $gutter 0;

    &.brand {
        background-color: $brand;

        .brand,
        .hero {
            color: $black;
        }
    }
}

@media (min-width: $bp-l) {
    .top-banner {
        padding: ($gutter * 3) 0;
    }
}

// @media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {

// }

// @media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {

// }
