// Sections
.section {
    padding: $gutter 0;
    background: {
        position: 50%;
        size: cover;
    }
}

.section-grey {
    background-color: $bg-lt;
}


.section-brand {
    background-color: $brand;
    color: $white;
}

.section-cta {
    background-color: $highlight;
    padding: $gutter 0;
    color: $white;

    .cta-box {
        display: flex;
        align-items: center;
    }

    p {
        margin: 0;
    }

    .btn {
        background-color: $white;
        color: $text;
        margin-left: $gutter;

        &:hover {
            background-color: $bg-lt;
        }
    }
}

@media (min-width: $bp-l) {
    .section {
        padding: ($gutter * 3) 0;
    }

    .section-cta {
        padding: ($gutter * 2);

        .cta-box {
            justify-content: space-between;
        }

        .cta-action {
            text-align: right;
            padding-top: 6px;
        }
    }
}

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    .section {
        padding: ($gutter * 2) 0;
    }

    .section-cta {
        padding: ($gutter * 2);

        .cta-action {
            text-align: right;
            padding-top: 6px;
        }
    }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .section-cta {
        text-align: center;

        .cta-action {
            padding-top: ($gutter / 2);
        }
    }
}

.section-tight {
    padding: 0;
}