// Main footer
.main-foot {
    background-color: $bg-dk;
    color: $white;
    position: relative;
    padding: ($gutter * 1.5) 0 1px 0;
    margin: 0;
    background: {
        position: 50%;
        repeat: no-repeat;
        size: cover;
    }

    a {
        color: $white;
    }

    h4 {
        color: $bg-lt;
    }
}

.copyright {
    border-top: 1px solid rgba($white, 0.1);
    background-color: rgba(mix($white, $bg-dk, 4%), 0.6);
    margin-top: $gutter;
    font-size: 0.8em;
    position: relative;

    .col12 {
        padding: ($gutter / 2) 0;
    }

    p {
        margin: 0;
        opacity: 0.6;
    }

    a {
        color: $white;
    }
}

.address {
    display: block;
    font-size: 0.9em;
    font-style: normal;
    margin: 0 0 1em 0;

    span {
        display: block;
    }
}

.totop {
    position: absolute;
    z-index: 99;
    display: block;
    left: 50%;
    margin-left: -20px;
    top: -20px;
    text-decoration: none;
    color: $white;
    font-size: 20px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 22px;
    background-color: $black;
    transition: color 0.15s linear;

    &:hover {
        color: $highlight;
    }
}

.foot-menu {
    margin: 0;
    padding: 12px 0;
    list-style: none;
}

.foot-item {
    position: relative;
    font-size: 0.9em;
    margin-bottom: 8px;

    [class^="icon-"] {
        color: $bg-lt;
    }
}

@media (min-width: $bp-l) {
    .main-foot {
        padding: ($gutter * 2) 0 1px 0;
        background-image: url('#{$img}bg-foot-lg.jpg');

        &.foot-columns {
            .social-title,
            .social-icons {
                text-align: right;
            }
        }
    }

    .copyright {
        margin-top: ($gutter * 2);
        padding: ($gutter / 2) 0;

        .col4 {
            text-align: right
        }
    }

    .foot-item {
        [class^="icon-"] {
            position: absolute;
            top: 2px;
            left: -32px;
        }
    }
}

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    .main-foot {
        background-image: url('#{$img}bg-foot-md.jpg');

        .container {
            position: relative;
        }

        .col2 {
            width: 15%;
        }

        .col4 {
            width: 33.33333333%;
        }

        .col6 {
            width: 51.66666666%;
        }

        &.foot-columns {
            .social-title,
            .social-icons {
                text-align: right;
            }
        }
    }

    .copyright {
        padding: ($gutter / 2) 0;

        .col4 {
            text-align: right
        }
    }

    .foot-item {
        [class^="icon-"] {
            position: absolute;
            top: 0;
            left: -24px;
        }
    }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .main-foot {
        text-align: center;
        background-image: url('#{$img}bg-foot-sm.jpg');
    }

    .copyright {
        text-align: center;
        padding: $gutter 0 ($gutter / 2) 0;

        p {
            padding: ($gutter / 4);
            margin: 0;
        }
    }

    .foot-menu {
        padding: 24px 0;
    }

    .foot-item {
        [class^="icon-"] {
            display: block;
            margin: 0 auto;
        }
    }
}
