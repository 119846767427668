// Content box
.content-box {
    background-color: $bg-lt;
    border-radius: $g-radius;
    margin: 0;
    padding: 0 0 0 (40px + ($gutter / 2));
    position: relative;

    &.box-white {
        background-color: $white;
    }

    .box-content {
        padding: ($gutter / 2);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .box-media {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 40px;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: $bp-l) {
    .content-box {
        padding-left: (120px + $gutter);

        .box-content {
            height: 160px;
            padding: $gutter;
        }

        .box-media {
            padding: 0 ($gutter / 2);
            width: 120px;
        }
    }
}

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    .content-box {
        padding-left: (120px + ($gutter * 2));
        margin: ($gutter / 2) 0;

        .box-media {
            width: 120px;
        }
    }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .content-box {
        margin: ($gutter / 4) 0;
        padding: 0;

        .box-media {
            position: static;
            width: auto;
            height: auto;
            padding: ($gutter / 2) 0;

            img {
                display: block;
                margin: 0 auto;
            }
        }
    }
}
