// Colours
$black:                         #000000;
$white:                         #ffffff;

$brand:                         #000000;
$highlight:                     #333;

// Greys
$bg-lt:                         #f6f6f6;
$bg-dk:                         #0d1918;
$border:                        #999;

$error:                         #ff0000;
$error-bg:                      #ffe5e5;

$success:                       #339900;
$success-bg:                    #eaf5e5;

$info:                          #3399ff;
$info-bg:                       #eaf5ff;

$warning:                       #ffff00;
$warning-bg:                    #ffffe5;

$text:                          #011627;
$title:                         #011627;

$g-radius:                      3px;
$gradius-lg:                    12px;

$dark-head:                     false;

$facebook:                      #3b5998;
$twitter:                       #1da1f2;
$youtube:                       #ff0000;
$instagram:                     #c32aa3;
$pinterest:                     #bd081c;
$linkedin:                      #2867B2;
$whatsapp:                      #25D366;

// Grid
$include-grid:                  true;
$col-qty:                       12;
$col-width:                     62px;
$gutter:                        24px;

// Large desktop grid
$xl-col-width:                  70px;
$xl-gutter:                     30px;

$m-max-width:                   780px;
$s-max-width:                   540px;

// Breakpoints
$bp-xl:                 		1420px;
$bp-l:              			1140px;
$bp-m:             				720px;
$bp-s:              			120px;

// Paths
$img:                           '../img/';
$sprite:                        '../img/sprite.png';
$fonts:                         '../fonts/';

// Typography
$body-font:                     'Lato', sans-serif;
$title-font:                    "Noto Serif", serif;
