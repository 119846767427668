// Videos
.video-player {
    position: relative;
    height: 0;
    padding-top: 56.25%;
    background: {
        color: $bg-dk;
        position: 50%;
        size: cover;
        repeat: no-repeat;
    }
    
    .btn-video,
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.btn-video {
    background-color: rgba($black, 0.5);
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    [class^="icon-"] {
        color: $brand;
        font-size: 36px;
    }

    .btn-video-txt {
        display: none;
    }
}

.banner {
    position: relative;
    overflow: hidden;
}

.bgvid { 
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    transition: 1s opacity;
    background: {
        image: url('/assets/img/video.jpg');
        size: cover;
    }
}

@media (min-width: $bp-l) {
    .btn-video {
        .icon-youtube {
            font-size: 48px;
        }
    }
}

// @media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {

// }

// @media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {

// }
