// Features
.feat {
    padding: ($gutter / 2) ($gutter / 2) ($gutter / 2) 44px;
    margin: 0 0 ($gutter / 2) 0;
    position: relative;

    &:hover {
        .feat-icon {
            background-color: $brand;
        }
    }
}

.feat-icon  {
    position: absolute;
    top: 6px;
    background-color: $bg-dk;
    color: $white;
    text-align: center;
    width: 32px;
    height: 32px;
    border-radius: 18px;
    line-height: 32px;
    left: 0;
    font-size: 20px;
    transition: background-color 0.15s linear;
}

@media (min-width: $bp-l) {
    .feat {
        font-size: 0.9em;
    }
}

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    .feat {
        // font-size: 0.8em;
    }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .feat {
        // font-size: 0.833em;
    }
}
