// * Grid system
// * Define the properties of the flexible container
// *

// Output the grid only if variable set to true
@if ($include-grid) {
    .wrap {
        overflow: hidden;
        width: 100%;
    }

    .container {
        width: 100%;
        padding: 0 ($gutter / 4);
        box-sizing: border-box;
        margin: 0 auto;
        max-width: $s-max-width;

        &:after {
            clear: both;
            content: '';
            display: block;
        }

        .container {
            width: 102%;
            width: calc(100% + #{$gutter});
            margin-left: -($gutter / 2);
        }
    }

    .col12,
    .col11,
    .col10,
    .col9,
    .col8,
    .col7,
    .col6,
    .col5,
    .col4,
    .col3 {
        margin: 0;
        padding: 0 ($gutter / 2);
        width: 100%;
        box-sizing: border-box;
    }

    .col2,
    .col1 {
        float: left;
        margin: 0;
        padding: 0 ($gutter / 4);
        width: 50%;
        box-sizing: border-box;
    }

    .s-full {
        float: none;
        margin: 0;
        padding: 0 ($gutter / 4);
        width: 100%;
        box-sizing: border-box;
    }

    .s-half {
        float: left;
        margin: 0;
        padding: 0 ($gutter / 4);
        width: 50%;
        box-sizing: border-box;
    }

    .s-1_3 {
        float: left;
        margin: 0;
        padding: 0 ($gutter / 4);
        width: 33.333333333333%;
        box-sizing: border-box;
    }

    .s-2_3 {
        float: left;
        margin: 0;
        padding: 0 ($gutter / 4);
        width: 66.666666666666%;
        box-sizing: border-box;
    }

    .clear {
        clear: both;
    }
}

// * Show or hide elements by breakpoint
.s-hide {
    display: none;
}

.s-show-block,
.s-show-inline {
    display: inline;
}

.m-show-block,
.m-show-inline,
.l-show-block,
.l-show-inline {
    display: none;
}
