// Forms
.form {
    background-color: $white;
    padding: $gutter;
    margin: 0;
    position: relative;
    overflow: hidden;

    fieldset {
        margin: 0;
        padding: 0;
        border: 0;
    }

    label {
        font-size: 1em;
        line-height: 1.25;
    }

    &.form-brand {
        background-color: $brand;
        color: $white;

        label {
            color: $white;
        }

        .btn-cta {
            background-color: mix($bg-dk, $brand, 60%);
            color: $white;

            &:hover {
                color: $white;
                background-color: $bg-dk;
            }
        }

        input[type="checkbox"].input-toggle {
            border: 2px solid mix($bg-dk, $brand, 60%);
            background-color: mix($bg-dk, $brand, 60%);

            &::before {
                background-color: $white;
            }

            &::after {
                color: $white;
            }
            &:checked {
                background-color: $highlight;
                border-color: $highlight;
            }
        }
    }

    #messages {
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        align-items: center;
        transition: all 0.25s ease-out;
        background-color: rgba($white, 0.95);

        .btn-msg-close {
            position: absolute;
            top: ($gutter / 2);
            right: ($gutter / 2);
            border: 0;
            background: none;
            display: block;
            height: 36px;
            width: 36px;
            line-height: 36px;
            text-align: center;
            font-size: 18px;
            color: $text;
            cursor: pointer;
        }
    }

    .form-msg {
        padding: $gutter;
        text-align: center;
        display: none;
    }

    #form_errors {
        margin: 0;
        padding: 0;
        font-size: 0.9em;
    }

    &.form-loading {
        &::before {
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: {
                color: rgba($white, 0.8);
                image: url('#{$img}ajax-loader.gif');
                position: 50%;
                repeat: no-repeat;
                size: 33px 33px;
            }
        }
    }

    &.form-error {
        #messages {
            height: 100%;
        }

        .form-msg {
            color: $error;
            display: block;
        }

        .btn-form-close {
            border-color: $error;
            color: $error;
        }
    }

    &.form-success {
        #messages {
            height: 100%;
        }

        .form-msg {
            color: $success;
            display: block;
        }
    }

    %field-basics {
        border: 1px solid $border;
        padding: ($gutter / 2);
        line-height: 1;
        height: 46px;
        width: 100%;
        background-color: $white;
        border-radius: $g-radius;
        font-size: 1em;
        font-weight: 400;
        outline: 0;
        font-family: $body-font;
        box-sizing: border-box;
        transition: all 0.15s linear;
        box-shadow: none;

        &:focus {
            outline: 0;
            border: 1px solid $black;
        }
    }

    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='url'],
    input[type='date'],
    input[type='tel'],
    input[type='number'] {
        @extend %field-basics;
    }

    textarea {
        @extend %field-basics;
        min-height: 120px;
        resize: vertical;
    }

    select {
        @extend %field-basics;
        padding-right: 46px;
        appearance: none;
        background: {
            image: url('#{$img}select-arrow.png');
            size: 25px 7px;
            position: 100% 50%;
            repeat: no-repeat;
        }
    }

    input.honeyfield {
        position: absolute;
        width: 1px;
        height: 1px;
        border: 0;
        background-color: transparent;
        padding: 0;
    }

    .label-tick {
        position: relative;
        padding: 6px 0 6px 24px;
        margin-right: $gutter;

        input[type="radio"],
        input[type="checkbox"] {
            position: absolute;
            top: 8px;
            left: 0;
        }
    }
}

.field {
    margin: 0 0 $gutter 0;

    &.required {
        label {
            font-weight: bold;
        }
    }

    &.invalid {
        input,
        select,
        textarea {
            border-color: $error !important;
        }
    }

    &.field-submit {
        position: relative;
        margin: 0;
    }
}

.form-errors {
    position: absolute;
    border-radius: 2px;
    top: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba($black, 0.8);
    color: $white;
    font-size: 0.8em;
    padding: ($gutter / 4);
    opacity: 0;
    transition: all 0.15s linear;

    &.show {
        opacity: 1;
    }
}


label.label-toggle {
    display: block;
    max-width: 320px;
    position: relative;
    padding: ($gutter / 4) 0;
    height: 34px;
    line-height: 34px;
    cursor: pointer;
}

input[type="checkbox"].input-toggle {
    appearance: none;
    display: block;
    float: left;
    border: 2px solid $border;
    height: 34px;
    width: 74px;
    border-radius: 20px;
    margin: 0 ($gutter / 2) 0 0;
    padding: 2px;
    outline: 0;
    transition: background 0.12s linear;
    background-color: $border;
    position: relative;
    cursor: pointer;

    &::before {
        content: "";
        display: block;
        position: absolute;
        width: 26px;
        height: 26px;
        top: 2px;
        left: 2px;
        transition: all 0.12s ease-out;
        background-color: $white;
        border-radius: 20px;
    }

    &::after {
        content: "no";
        display: block;
        position: absolute;
        color: $white;
        font-size: 10px;
        text-transform: uppercase;
        position: absolute;
        left: 36px;
        top: 0;
        line-height: 30px;
    }

    &:active {
        &::before {
            width: 32px;
        }
    }

    &:checked {
        background-color: $brand;
        border-color: $brand;

        &:before {
            left: auto;
            right: 2px;
        }

        &::after {
            content: "yes";
            left: 14px;
        }
    }
}


@media (min-width: $bp-l) {
    .form {
        padding: ($gutter * 2);

        .label-tick {

            input[type="radio"],
            input[type="checkbox"] {
                top: 10px;
            }
        }
    }

    .fieldgroup {
        display: flex;
        justify-content: space-between;

        .field50 {
            flex: 0 0 48%;
        }

        .field33 {
            flex: 0 0 31%;
        }
    }
}

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    .fieldgroup {
        display: flex;
        justify-content: space-between;

        .field {
            flex: 0 0 48%;
        }
    }

    .fieldgroup {
        display: flex;
        justify-content: space-between;

        .field50 {
            flex: 0 0 48%;
        }

        .field33 {
            flex: 0 0 31%;
        }
    }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .form {
        padding: $gutter ($gutter / 2);
    }
}
