// Lists
.list {
    margin: 0 0 $gutter 0;
    list-style: none;
    padding: 0;

    &::last-child {
        margin: 0;
    }
}

.list-item {
    padding: 0 0 0 $gutter;
    position: relative;
    margin: 0 0 ($gutter / 2) 0;

    [class^="icon-"] {
        position: absolute;
        top: 1px;
        left: -4px;
        color: $brand;
    }
}

// @media (min-width: $bp-l) {

// }

// @media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {

// }

// @media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {

// }
