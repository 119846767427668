// Boxes
.boxes {
    display: flex;
    justify-content: space-between;
}

.box {
    border-radius: $g-radius;
    overflow: hidden;
    background-color: $bg-lt;
}

.box-brand {
    background-color: $brand;
    color: $white;

    a {
        color: $bg-lt;
    }
}

.section-grey {
    .box {
        background-color: $white;
    }
}

.box-icon {
    padding: $gutter;
    text-align: center;
    height: 50px;
}

.box-img {
    img {
        display: block;
    }
}

.box-cont {
    padding: $gutter;
}

@media (min-width: $bp-l) {
    .boxes-2 {
        .box {
            flex: 1 1 50%;
        }
    }

    .boxes-3 {
        .box {
            flex: 1 1 33%;
        }
    }

    .boxes-4 {
        .box {
            flex: 1 1 25%;
        }
    }

    .box {
        margin: 0 ($gutter / 2);

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

@media (min-width: $bp-m) and (max-width: ($bp-l - 1)) {
    .boxes-2 {
        .box {
            flex: 1 1 49%;
        }
    }

    .boxes-3 {
        .box {
            flex: 1 1 32%;
        }
    }

    .boxes-4 {
        .box {
            flex: 1 1 24%;
        }
    }

    .box {
        margin: 0 ($gutter / 4);

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

@media (min-width: $bp-s) and (max-width: ($bp-m - 1)) {
    .boxes {
        flex-direction: column;
    }

    .box {
        margin: 0 0 ($gutter / 2) 0;

        &:last-child {
            margin: 0;
        }
    }

    .box-icon {
        padding: ($gutter / 2);
    }

    .box-cont {
        padding: ($gutter / 2);
    }
}
